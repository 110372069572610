<template>
  <div class="main">
    <div class="imgDiv">
      <img v-show="imgShow==false" src="../assets/img.gif" />
      <img v-show="imgShow==true" src="../assets/unfound.jpg" />
    </div>
    <div class="boxright">
      <h1>抱歉！要访问的页面不存在</h1>
      <h2>让医生有力量，让患者有希望</h2>
      <div class="deriction">
        <p v-show="showActive">{{timeNum}}秒之后自动跳转</p>
        <span>您还可以：</span>
        <button @click="gobrow">浏览官网</button>
        <button @click="goBack">返回上一页</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notFound",
  data() {
    return {
      timeNum: 5,
      showActive: true,
      imgShow: false
    };
  },
  mounted() {
    if (localStorage.getItem("redirect_uri")) {
      this.showActive = true;
      this.timeshort();
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        console.log("yes");
        this.imgShow = true;
      } else {
        console.log("no");
        this.imgShow = false;
      }
    } else {
      this.showActive = false;
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        console.log("yes");
        this.imgShow = true;
      } else {
        console.log("no");
        this.imgShow = false;
      }
    }
  },
  methods: {
    //5秒倒计时
    timeshort() {
      var that = this;
      var timer = setInterval(function() {
        if (that.timeNum == 0) {
          clearInterval(timer);
          that.$router.push("/");
        } else {
          that.timeNum--;
        }
      }, 1000);
    },
    gobrow() {
      //点击跳转外链
      window.location.href = "http://www.canhelpdx.com/";
    },
    goBack() {
      this.$router.go(-1); //返回上一层
    }
  }
};
</script>

<style scoped>
body {
  background: #ffffff;
  margin: 0 auto;
}
.main {
  margin: 5% 10% 0;
  display: flex;
  font-family: cursive;
}
.boxright {
  box-sizing: border-box;
  padding: 5%;
  width: 100%;
  text-align: center;
}
.boxright h1 {
  font-weight: normal;
}
.boxright h2 {
  margin: 10% auto;
  background: linear-gradient(to right, #037fbf, #57dbac);
  -webkit-background-clip: text;
  /* color: transparent; */
  color: white;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(#037fbf),
    to(#57dbac)
  );
  -webkit-text-fill-color: transparent;
}
.deriction p {
  font-size: 20px;
}
.deriction span {
  font-size: 22px;
}
.boxright .deriction button {
  cursor: pointer;
  font-size: 20px;
  padding: 10px 15px;
  font-family: cursive;
  border: 0;
  color: #037fbf;
  margin: 5% 2%;
  background: #ffffff;
}
.boxright .deriction button:hover {
  color: #ffffff;
  border: none;
  background: linear-gradient(to right, #037fbf, #57dbac);
}
@media only screen and (max-width: 1200px) {
  .imgDiv img {
    width: 470px;
    height: 470px;
  }
  .boxright h1 {
    font-size: 30px;
  }
  .boxright h2 {
    margin: 15% auto;
  }
}
@media only screen and (max-width: 992px) {
  .imgDiv img {
    width: 400px;
    height: 400px;
  }
  .boxright h1 {
    font-size: 28px;
  }
  .boxright h2 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 768px) {
  .imgDiv img {
    width: 400px;
    height: 400px;
  }
  .main {
    text-align: center;
    display: block;
  }
  .boxright h2 {
    margin: 10% auto;
  }
}
@media only screen and (max-width: 576px) {
  .imgDiv img {
    width: 350px;
    height: 350px;
  }
}
@media only screen and (min-width: 1200px) {
}
</style>
